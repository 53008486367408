import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")
  const _component_ion_grid = _resolveComponent("ion-grid")
  const _component_ion_card_header = _resolveComponent("ion-card-header")
  const _component_ion_toast = _resolveComponent("ion-toast")
  const _component_ion_card_content = _resolveComponent("ion-card-content")
  const _component_ion_card = _resolveComponent("ion-card")

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.viewDocument()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, { color: "tertiary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { class: "ion-text-start" }, {
                    default: _withCtx(() => [
                      (_ctx.doc.materia)
                        ? (_openBlock(), _createBlock(_component_ion_text, {
                            key: 0,
                            color: "secondary"
                          }, {
                            default: _withCtx(() => [
                              _createVNode("b", null, _toDisplayString(_ctx.doc.materia), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (!_ctx.doc.materia)
                        ? (_openBlock(), _createBlock(_component_ion_text, {
                            key: 1,
                            color: "secondary"
                          }, {
                            default: _withCtx(() => [
                              _createVNode("b", null, _toDisplayString(_ctx.doc.categories[0].toUpperCase().replaceAll('-', ' ')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { class: "ion-text-end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, { color: "secondary" }, {
                        default: _withCtx(() => [
                          _createVNode("b", null, _toDisplayString(_ctx.doc.dateFormatted), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "1",
                    class: "ion-text-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: 
                _ctx.doc.access == 1 || (_ctx.doc.access != 1 && !_ctx.paywall)
                  ? 'unlocked hydrated'
                  : 'locked hydrated'
              ,
                        name: 
                _ctx.doc.access == 1 || (_ctx.doc.access != 1 && !_ctx.paywall)
                  ? 'lock-open'
                  : 'lock-closed'
              
                      }, null, 8, ["class", "name"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { size: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createVNode("h6", null, [
                            _createVNode("b", null, _toDisplayString(_ctx.doc.title), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_card_subtitle, { class: "abstract" }, {
                        default: _withCtx(() => [
                          _createVNode("i", null, [
                            _createVNode("div", {
                              class: "abstract",
                              innerHTML: _ctx.doc.abstract
                            }, null, 8, ["innerHTML"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, { class: "text" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toast, {
            "is-open": _ctx.showAlert,
            message: _ctx.alertMessage,
            duration: 2000,
            onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setAlertOpen(false))),
            color: "secondary"
          }, null, 8, ["is-open", "message"]),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode("div", {
                        class: "text",
                        innerHTML: _ctx.showAbstract ? _ctx.doc.abstract : _ctx.doc.shortText1
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}